import * as React from 'react';

import useProject from '@circleci/web-ui-data/build/queries/project/hook';
import { useRouter } from 'next/router';
import * as R from 'ramda';

import NotFound from '~/project-settings/components/shared/NotFound';
import PageLoading from '~/project-settings/components/shared/PageLoading';

interface Props {
  children: React.ReactNode;
}

export interface ProjectPropsContextValues {
  vcsType: string;
  orgName: string;
  projectName: string;
  orgDisplayName: string;
  orgId: string;
  projectDisplayName: string;
  projectId?: string;
}
const ProjectPropsContext = React.createContext<ProjectPropsContextValues>(
  null as any,
);

const castToString = (val: string | string[]) => val as string;

export const ProjectPropsProvider = ({ children }: Props) => {
  const router = useRouter();
  const {
    vcsType = '',
    orgName = '',
    projectName = '',
  } = R.mapObjIndexed(castToString, router.query);
  const { project, loading } = useProject({
    projectSlug: `${vcsType}/${orgName}/${projectName}`,
  });

  if (loading) {
    return <PageLoading />;
  }

  if (R.isEmpty(router.query)) {
    return <NotFound />;
  }

  return (
    <ProjectPropsContext.Provider
      value={{
        vcsType,
        orgName,
        projectName,
        orgId: project?.organization.id,
        orgDisplayName: project?.organization.name || orgName,
        projectDisplayName: project?.name || projectName,
        projectId: project?.id,
      }}
    >
      {children}
    </ProjectPropsContext.Provider>
  );
};

export const useProjectProps = () => {
  const props =
    React.useContext<ProjectPropsContextValues>(ProjectPropsContext);

  if (R.isNil(props)) {
    throw new Error('ProjectPropsProvider not available');
  }

  return props;
};

export default ProjectPropsContext;
