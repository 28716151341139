import Head from 'next/head';
import * as React from 'react';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import Modal from 'react-modal';
import { CompassThemeProvider } from '@circleci/compass';
import { PersistOrgId } from '@circleci/react-experiments';
import { client, Provider as GraphQLProvider } from '~/graphql';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Analytics } from '@circleci/analytics';
import { OrgProvider, OrgContext } from '@circleci/web-ui-data';
import scalarQueryParam from '~/utils/queryParams/scalarQueryParam';

import { initPusher } from '~/utils/getPusher';
import MeContext, { MeProvider } from '~/contexts/MeContext';
import HotJarProvider from '~/components/HotJarProvider';
import PageLoading from '~/components/shared-components/PageLoading';
import { renderInviteSlackUnfurl } from '~/engagement-experiments/utils/inviteSlackUnfurlUtil';
import { MaintenancePage } from '@circleci/maintenance';

// Register the exception logger to catch all uncaught exceptions
import ConnectivityToast from '~/components/ConnectivityToast';
import TrackSharedLink from '~/components/shared-components/TrackSharedLink';

// TEMP
import OptimizelyFullStackProvider from '~/components/OptimizelyFullStackProvider';
import { EmailVerification } from '@circleci/web-ui-nav';
import { vcsAndOrgLacking } from '~/utils/vcs';

import { ProjectPropsProvider } from '~/project-settings/contexts/ProjectPropsContext';
import { ReturnToProvider } from '~/project-settings/contexts/ReturnToContext';

import '@reach/dialog/styles.css';

const EmailVerificationWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { me } = React.useContext(MeContext);
  return <EmailVerification me={me}>{children}</EmailVerification>;
};

class PageTemplate extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    initPusher();
    if (document.getElementById('__next')) {
      Modal.setAppElement('#__next');
    }
  }
  render() {
    const { children, router } = this.props;
    const query = router?.query ?? {};
    const vcsType = scalarQueryParam(query.vcsType);
    const orgName = scalarQueryParam(query.orgName || query.username);

    const skipMe = router.route === '/pipelines/healthcheck';
    // This is for 404 routes so avoid the SSO checks, more pages will be added here
    // like '/create-organization' etc.. as we continue consolidation work
    const skipLoadingOrgInfo =
      ['/dashboard', '/api/login', '/error-page', '/'].includes(router.route) ||
      vcsAndOrgLacking(vcsType, orgName);

    return (
      <>
        <Head>
          <title>CircleCI</title>
          {renderInviteSlackUnfurl(router)}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            id="apple-touch-favicon"
            key="apple-touch-favicon"
            rel="apple-touch-icon"
            sizes="180x180"
            href="/pipelines/favicons/apple-touch-icon.png"
          />
          <link
            id="large-favicon"
            key="large-favicon"
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/pipelines/favicons/favicon-32x32.png"
          />
          <link
            id="small-favicon"
            key="small-favicon"
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/pipelines/favicons/favicon-16x16.png"
          />
        </Head>
        <ApolloHooksProvider client={client}>
          <GraphQLProvider>
            <QueryClientProvider
              client={
                new QueryClient({
                  defaultOptions: { queries: { refetchOnWindowFocus: false } },
                })
              }
              contextSharing={true}
            >
              <Analytics>
                <CompassThemeProvider>
                  <ConnectivityToast />

                  {skipMe ? (
                    children
                  ) : (
                    <MeProvider>
                      {skipLoadingOrgInfo ? (
                        // There is a fair bit of repetition here, this is to avoid
                        // having to call SSO endpoints for parts of the app
                        // that don't need it
                        // TODO: eventually rip this out in a function to avoid duplication
                        <HotJarProvider>
                          <OptimizelyFullStackProvider>
                            <MaintenancePage>
                              <EmailVerificationWrapper>
                                <TrackSharedLink />
                                {children}
                              </EmailVerificationWrapper>
                            </MaintenancePage>
                            <PersistOrgId query={router.query} />
                          </OptimizelyFullStackProvider>
                        </HotJarProvider>
                      ) : (
                        <OrgProvider
                          orgIdOrSlug={encodeURIComponent(
                            `${vcsType}/${orgName}`,
                          )}
                        >
                          <OrgContext.Consumer>
                            {({ loading }) => {
                              if (loading) {
                                return <PageLoading />;
                              }

                              // TODO: this needs to be enabled
                              // at some point, but currently does not work
                              // because this would error for unauthenticated users viewing OSS pages
                              // if (error) {
                              //   return <Error404 />;
                              // }

                              return (
                                <HotJarProvider>
                                  <ReturnToProvider>
                                    <ProjectPropsProvider>
                                      <OptimizelyFullStackProvider>
                                        <MaintenancePage>
                                          <EmailVerificationWrapper>
                                            <TrackSharedLink />
                                            {children}
                                          </EmailVerificationWrapper>
                                        </MaintenancePage>
                                        <PersistOrgId query={router.query} />
                                      </OptimizelyFullStackProvider>
                                    </ProjectPropsProvider>
                                  </ReturnToProvider>
                                </HotJarProvider>
                              );
                            }}
                          </OrgContext.Consumer>
                        </OrgProvider>
                      )}
                    </MeProvider>
                  )}
                </CompassThemeProvider>
              </Analytics>
            </QueryClientProvider>
          </GraphQLProvider>
        </ApolloHooksProvider>{' '}
      </>
    );
  }
}

export default PageTemplate;
