import * as React from 'react';

import styled from '~/styled';
import { mediaDesktop } from '~/components/shared-components/Viewports';
import { NavBar } from '~/components/NavBar';
import { TopNav } from '@circleci/web-ui-nav';
import { Flex } from '@circleci/compass';

import { PageContent } from '~/components/PageContent';
import MeContext from '~/contexts/MeContext';

export const Wrapper = styled(Flex)`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  ${mediaDesktop} {
    flex-direction: row;
  }
`;

interface Props {
  children: React.ReactNode;
  isNavless: boolean;
}

export const PageLayout = (props: Props) => {
  const { me } = React.useContext(MeContext);
  if (props.isNavless) {
    return (
      <PageContent
        css={{
          [mediaDesktop]: {
            borderTopRightRadius: '12px',
          },
        }}
      >
        {props?.children}
      </PageContent>
    );
  }

  return (
    <>
      <TopNav me={me} />
      <Wrapper backgroundColor={'cg800'} paddingX={'space8'}>
        <NavBar />
        <PageContent
          css={{
            [mediaDesktop]: {
              borderTopRightRadius: '12px',
            },
          }}
        >
          {props?.children}
        </PageContent>
      </Wrapper>
    </>
  );
};
